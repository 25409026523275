import '../css/style.scss';

function scrollHeader() {
    const header = document.querySelector('.header-wrapper')
    const body = document.querySelector('body');
    if (header) {
        window.addEventListener('scroll', () => {
            const navTop = header.offsetHeight
            if (window.scrollY > navTop) {
                if (!header.classList.contains('so-fix')) {
                    header.classList.add('so-fix')
                    body.classList.add('so-fix');
                }
            } else {
                if (header.classList.contains('so-fix')) {
                    header.classList.remove('so-fix')
                    body.classList.remove('so-fix');
                }
            }
        })
    }
}

function sliderHome() {
    const slider = document.querySelector('.swiper-once')
    if (slider) {
        const swiper = new Swiper(slider, {
            autoplay: {
                delay: 5000,
            },
            // If we need pagination
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        })
    }
}

function sliderActu() {
    const slider = document.querySelector('.swipper-multi')
    if (slider) {
        const swiper = new Swiper(slider, {
            slidesPerView: 3,
            spaceBetween: 30,
            // If we need navigation
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },

        })
    }
}

function sliderLandHouse() {
    const slider = document.querySelector('.swipper-multi-land')
    if (slider) {
        const swiper = new Swiper(slider, {
            slidesPerView: 3,
            spaceBetween: 30,
            // If we need navigation
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: '.swiper-pagi',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.3,
                    spaceBetween: 30,
                },
                660: {
                    slidesPerView: 2.3,
                    spaceBetween: 30,
                },
                1244: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
            },

        })
    }
}

function scroll() {
    ScrollReveal().reveal('.reveal-from-bottom', {
        origin: 'bottom',
        distance: '60px',
        duration: 1000,
        delay: 600
    });
    ScrollReveal().reveal('.reveal-from-left', {
        origin: 'left',
        distance: '60px',
        duration: 1000,
        delay: 600
    });
    ScrollReveal().reveal('.reveal-from-right', {
        origin: 'right',
        distance: '60px',
        duration: 1000,
        delay: 600
    });
}
(function() {
    const homeClass = document.querySelector('.homehome')
    if (homeClass) {
        sliderHome()
        sliderActu()
        sliderLandHouse()
        scroll()
        scrollHeader()

    } else {
        scrollHeader()
    }
})()